import React from "react";
import "./infoapp.css";
import { Fade } from "react-awesome-reveal";
const Infoappr = ({
  title,
  desc,
  link,
  custom1,
  custom2,
  custom3,
  img,
  imgc,
}) => {
  return (
    <>
      {" "}
      <section id="app2">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <Fade direction="right" triggerOnce="false">
                {/* <div className="img-cc"> */}
                <img
                  style={{
                    width: "400px",
                    borderRadius: "20px",
                    boxShadow:
                      "0 16px 25px rgba(0,0,0,0.25), 0 11px 11px rgba(0,0,0,0.25)",
                  }}
                  src={img}
                  className="img-responsive"
                  alt="feature img"
                />
                {/* <img style={{width:"400px"}} src={imgc} className="img-responsive" alt="feature img" /> */}
                {/* </div> */}
              </Fade>
            </div>
            <div className="col-md-6">
              <Fade direction="left" triggerOnce="false">
                <h2 className="text-uppercase">{title}</h2>
                <h4 className="text-uppercase">{custom2}</h4>
                <h5 className="text-uppercase">{custom1}</h5>
                <h5 className="text-uppercase">{custom3}</h5>
                <p>{desc}</p>
                <a
                  className="btn btn-primary text-uppercase"
                  href={link}
                  target="_blank">
                  سجل الان
                </a>
              </Fade>
            </div>
          </div>
        </div>
      </section>
      
    </>
  );
};

export default Infoappr;
