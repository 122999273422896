import React, { useState } from "react";
import "./contact.css";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "@emailjs/browser";
import { Spinner } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
const Contact = () => {
  const [name, setName] = useState("");
  const [email, setemail] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [Loading, setLoading] = useState(false);
  const notify = (msg, type) => {
    if (type === "warn")
        toast.warn(msg)
    else if (type ==="success")
        toast.success(msg)
    else if (type ==="error")
        toast.error(msg)
};
  const sendEmail = (e) => {
    e.preventDefault();
    if(name===""||email===""||subject===""||message===""){
      notify('من فضلك اكمل البيانات المطلوبة','error')
      return
    }
    setLoading(true);
    emailjs
      .send(
        "service_miry0e7",
        "template_wmzhupc",
        {
          name:name,
          email:email,
          subject:subject,
          message:message,
        },
        "XvO8s0zbcgmjO7eMH"
      )
      .then(
        (result) => {
          setLoading(false);
          notify("تم إرسال استفساركم بنجاح", "success");
          setName("");
          setemail("");
          setmessage("");
          setsubject("");
        },
        (error) => {
          setLoading(false);
          setName("");
          setemail("");
          setmessage("");
          setsubject("");
          notify("فشل إرسال استفساركم ", "error");
        }
      );
  };
  return (
    <>
      <section id="contact">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <h2 className="text-uppercase">تواصل معنا</h2>
                <p>
                  اذا كان لديك أسئلة أو اهتمامات حول جمعنا واستخدامنا لمعلوماتك
                  الشخصية ,الرجاء الاتصال بفريق خدمة العملاء على رابط "تواصل
                  معنا" على “موقع أعمال سوفت وير”.
                </p>
                <address>
                  <a href="/">
                    <i
                      style={{ color: "#fff" }}
                      className="fa fa-brands fa-linkedin"
                    ></i>
                  </a>

                  <a href="https://wa.me/+201001122424">
                    <i style={{ color: "#fff" }} className="fa fa-phone"></i>
                  </a>
                  <a href="/">
                    <i
                      style={{ color: "#fff" }}
                      className="fa fa-brands fa-facebook"
                    ></i>
                  </a>
                  <a href="https://www.youtube.com/@a3maalapps">
                    <i
                      style={{ color: "#fff" }}
                      className="fa fa-brands fa-youtube"
                    ></i>
                  </a>
                </address>
              </div>
              <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                <div className="contact-form">
                  <form action="#" method="post">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="الاسم"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="email"
                        required
                        className="form-control"
                        value={email}
                        placeholder="الايميل"
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="الموضوع"
                        value={subject}
                        required
                        onChange={(e) => setsubject(e.target.value)}
                      />
                    </div>
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        placeholder="الرسالة"
                        required
                        value={message}
                        rows="4"
                        onChange={(e) => setmessage(e.target.value)}
                      ></textarea>
                    </div>
                    <div className="col-md-8">
                      {Loading ? (
                        <button className="buttcontact">
                          <Spinner />
                        </button>
                      ) : (
                        <button className="buttcontact" onClick={sendEmail}>
                          إرسال
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Contact;
