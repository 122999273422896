import React from "react";
import "./intro.css";
import img1 from "../images/1.jpg";
import img2 from "../images/2.jpg";
import img3 from "../images/3.jpg";
import { Carousel } from "react-bootstrap";
import { Fade } from "react-awesome-reveal";
const Intro = () => {
  return (
    <div className="car">
      <div className="overlay"></div>
      <Carousel fade>
        <Carousel.Item>
          <img className="d-block w-100" src={img3} alt="First slide" />

          <Carousel.Caption>
            <div className="slider-caption">
              <Fade direction="up">
                <h3>أعمال</h3>
                <p>
                  "موقع أعمال سوفت وير" منصة لعرض وتشغيل خدمة إستخدام برامج
                  أعمال المعتمدة على تقنية SaaS مما يتيح تجربة مميزة للعملاء في
                  استخدام البرامج لإنجاز مهامهم اليومية سواء للأفراد او أعمال
                  المكاتب أو الشركات متناهية الصغر والصغيرة
                </p>
              </Fade>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={img2} alt="Second slide" />
          <Carousel.Caption>
        <Fade direction="up">
              <h3>أعمال</h3>
              <p>
                مجموعة من البرمجيات مبنية على SaaS تكنولوجي التي يستخدمها
                العملاء لتسهيل أعمالهم اليومية سنساعدك على إدارة مهام أعمالك
                بأعلى كفاءة ، من أي مكان وفي أي وقت وبأقل التكاليف.
              </p>
            </Fade>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={img1} alt="Third slide" />
          <Carousel.Caption>
        <Fade direction="up">
              <h3>أعمال</h3>
              <p>
                تقنية SaaS هي أحد نماذج تقديم البرمجيات القائمة على السحابة
                الإلكترونية، حيث نقوم بتطوير برمجيات تطبيقية سحابية وحفظها
                وتوفير تحديثات تلقائية للبرمجيات وإتاحة البرمجيات لعملائنا عبر
                الإنترنت على أساس نظام الدفع الشهري أو السنوي
              </p>
            </Fade>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default Intro;
